module.exports = [{
      plugin: require('../../.yarn/__virtual__/gatsby-plugin-manifest-virtual-811d4488e9/0/cache/gatsby-plugin-manifest-npm-5.3.1-49cc0e3afe-9b9cda068f.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/gatsby-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4a9773549091c227cd2eb82ccd9c5e3a"},
    },{
      plugin: require('../../.yarn/__virtual__/gatsby-plugin-layout-virtual-1cb4126fc7/0/cache/gatsby-plugin-layout-npm-4.3.0-30d4d371ff-a2d7b8f621.zip/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/nick/src/sites/static-web-sites/site-manager/src/components/main-layout.jsx"},
    },{
      plugin: require('../../plugins/gatsby-plugin-global-config/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../.yarn/__virtual__/gatsby-virtual-a01f42cce2/0/cache/gatsby-npm-5.3.3-f708a17dce-10ae4426b0.zip/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
