import React, {useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useUserService, useUserDispatch} from "../user-context";
import './LoginForm.scss';
import {
    BLANK_NOTIFICATION,
    EMAIL_PATTERN, MODE_CONFIRM_EMAIL,
    MODE_LOGIN,
    MODE_REGISTER,
    MODE_RESTORE_PASSWORD, MODE_RESTORE_PASSWORD_CONFIRM,
    SUBMIT_LABEL
} from "./LoginConstants";
import {usePasswordValidator} from "./PasswordSchema";
import PropTypes from "prop-types";

LoginForm.propTypes = {
    onModeChange: PropTypes.func,
}

LoginForm.defaultProps = {
    onModeChange: () => {
    },
}

let PASSWORD_VALIDATION_MESSAGES = {}

const NO_OP_MODE_CHANGE_LISTENER = () => {
};

function LoginForm({onModeChange = NO_OP_MODE_CHANGE_LISTENER, initialMode = MODE_LOGIN}) {
    const {
        register,
        handleSubmit,
        formState: {errors},
        reset
    } = useForm();

    const {
        configuration: {
            configured,
            minPasswordLength
        },
        login,
        register: registerUser,
        restorePassword,
        socialLogin,
        confirmEmail,
        resendConfirmEmail
    } = useUserService();

    const dispatch = useUserDispatch();

    const [mode, setMode] = useState(initialMode);
    const [processing, setProcessing] = useState(false);
    const [notification, setNotification] = useState(BLANK_NOTIFICATION);
    const [passwordRules, setPasswordRules] = useState([]);
    const isActive = useRef(false);
    const passwordValidator = usePasswordValidator({
        minPasswordLength: minPasswordLength
    });

    useEffect(() => {
        if(configured) {
            PASSWORD_VALIDATION_MESSAGES = {
                min: `Password should be at least ${minPasswordLength} symbols`
            }
        }
    }, [configured, minPasswordLength])

    useEffect(() => {
        isActive.current = true;

        return () => {
            isActive.current = false
        }
    }, []);

    const resetComponent = function (resetErrorMessage = false, resetData = {}) {
        if (!isActive.current) {
            return;
        }

        reset(resetData);
        setProcessing(false);
        resetErrorMessage && setNotification(BLANK_NOTIFICATION);
    }

    function enterMode(mode) {
        return (e) => {
            e.preventDefault();
            resetComponent(true);
            setModeAndBroadcast(mode);
        }
    }

    function setModeAndBroadcast(mode) {
        setMode(mode);
        onModeChange(mode);
    }

    async function processSubmit(data) {
        setProcessing(true);
        setNotification(BLANK_NOTIFICATION);

        switch (mode) {
            case MODE_LOGIN:
                try {
                    await login(data.email, data.password, dispatch);
                } catch (error) {
                    let errorMessage = error.message;

                    if (error.code === 'UserNotConfirmedException') {
                        errorMessage = 'Your email is not confirmed.'

                        try {
                            await resendConfirmEmail(data.email);
                            setModeAndBroadcast(MODE_CONFIRM_EMAIL);
                            errorMessage = `${errorMessage} We sent you a confirmation code. Please check your email and enter the code below`;
                        } catch (resendError) {
                            setModeAndBroadcast(MODE_LOGIN);
                            errorMessage = `${errorMessage} Verification code delivery failed: ${resendError.message}`;
                        }
                    }

                    setNotification({
                        type: 'danger',
                        message: `Login failed: ${errorMessage}`
                    });

                } finally {
                    resetComponent(
                        false,
                        {email: data.email}
                    );
                }
                break;
            case MODE_REGISTER:
                try {
                    let userData = await registerUser(data.email, data.password);
                    let registrationMessage = 'Please login.';
                    let nextMode = MODE_LOGIN;

                    if (userData.userConfirmed === false) {
                        registrationMessage = 'We sent you a confirmation code. Please check your email and enter the code below'
                        nextMode = MODE_CONFIRM_EMAIL;
                    }

                    setNotification({
                        type: 'success',
                        message: `Registration successful! ${registrationMessage}`
                    });

                    setModeAndBroadcast(nextMode);
                } catch (error) {
                    setNotification({
                        type: 'danger',
                        message: `Registration failed: ${error.message}`
                    });
                } finally {
                    resetComponent(
                        false,
                        {email: data.email}
                    );
                }
                break;
            case MODE_CONFIRM_EMAIL:
                try {
                    await confirmEmail(data.email, data.code);
                    setNotification({
                        type: 'success',
                        message: 'Email confirmed! Please login.'
                    });
                    setModeAndBroadcast(MODE_LOGIN);
                } catch (error) {
                    setNotification({
                        type: 'danger',
                        message: `Email not confirmed: ${error.message}`
                    });
                } finally {
                    resetComponent(
                        false,
                        {email: data.email}
                    );
                }
                break;
            case MODE_RESTORE_PASSWORD:
                try {
                    await restorePassword(data.email);
                    setNotification({
                        type: 'success',
                        message: 'Please check your email and enter the code below'
                    });
                    setModeAndBroadcast(MODE_RESTORE_PASSWORD_CONFIRM);
                } catch (error) {
                    setNotification({
                        type: 'danger',
                        message: `Password cannot be reset: ${error.message}`
                    });
                } finally {
                    resetComponent(
                        false,
                        {email: data.email}
                    );
                }
                break;
            case MODE_RESTORE_PASSWORD_CONFIRM:
                try {
                    await restorePassword(data.email);
                    setNotification({
                        type: 'success',
                        message: 'Password reset successfully. Please login.'
                    });
                    setModeAndBroadcast(MODE_LOGIN);
                } catch (error) {
                    setNotification({
                        type: 'danger',
                        message: `Password cannot be reset: ${error.message}`
                    });
                } finally {
                    resetComponent(
                        false,
                        {email: data.email}
                    );
                }
                break;
            default:
                throw new Error('Unknown mode');
        }
    }

    function validatePassword(password) {
        if (![MODE_REGISTER, MODE_RESTORE_PASSWORD_CONFIRM].includes(mode)) {
            return true;
        }
        const validationResult = passwordValidator.validate(password, {list: true})

        setPasswordRules(validationResult);

        return validationResult.length === 0;
    }

    if (configured === false) {
        return (
            <div>Login form not configured</div>
        )
    }

    return (
        <div className="bg-white justify-content-center align-items-center">
            {notification.message &&
            <div className={'alert alert-' + notification.type + ' text-center'} role="alert">
                {notification.message}
            </div>
            }

            <form method="POST" onSubmit={handleSubmit(processSubmit)} noValidate>
                <FormField
                    fieldType={"email"}
                    fieldId={"email"}
                    fieldLabel={"Email"}
                    registerFn={register}
                    registerOptions={{required: true, pattern: EMAIL_PATTERN}}
                    errors={errors}
                    errorMessage={"Please enter a valid email"}
                    readOnly={[MODE_CONFIRM_EMAIL, MODE_RESTORE_PASSWORD_CONFIRM].includes(mode)}
                />
                {[MODE_CONFIRM_EMAIL, MODE_RESTORE_PASSWORD_CONFIRM].includes(mode) &&
                    <FormField
                        fieldId={"code"}
                        fieldLabel={"Code"}
                        registerFn={register}
                        registerOptions={{required: true}}
                        errors={errors}
                        errorMessage={"Please enter a code"}
                    />
                }
                {![MODE_CONFIRM_EMAIL, MODE_RESTORE_PASSWORD].includes(mode) &&
                    <FormField
                        fieldType={"password"}
                        fieldId={"password"}
                        fieldLabel={"Password"}
                        registerFn={register}
                        registerOptions={{required: true, validate : validatePassword}}
                        errors={errors}
                        errorMessage={"Please enter valid password"}
                    />
                }
                <button type="submit" disabled={processing}
                        className="btn btn-primary btn-block text-capitalize w-100">
                    {processing && <span className="spinner-border spinner-border-sm me-2"></span>}
                    {SUBMIT_LABEL[mode]}
                </button>
            </form>

            {mode === MODE_LOGIN &&
            <div>
                <p className="text-center mt-2 mb-2">Don't have an account?
                    <a href="#" className="text-dark fw-bold" onClick={enterMode(MODE_REGISTER)}> Sign
                        up!</a>
                </p>
                <a href="#" className="text-center text-muted d-block"
                   onClick={enterMode(MODE_RESTORE_PASSWORD)}>
                    Forgot you password?</a>
            </div>
            }

            {mode === MODE_REGISTER &&
            <p className="text-center mt-2 mb-2">Already have an account?
                <a href="#" className="text-dark fw-bold"
                   onClick={enterMode(MODE_LOGIN)}> Sign in!</a>
            </p>
            }

            {mode === MODE_CONFIRM_EMAIL &&
            <p className="text-center mt-2 mb-2">Have another account?
                <a href="#" className="text-dark fw-bold"
                   onClick={enterMode(MODE_LOGIN)}> Sign in!</a>
            </p>
            }

            {[MODE_RESTORE_PASSWORD, MODE_RESTORE_PASSWORD_CONFIRM].includes(mode) &&
            <p className="text-center mt-2 mb-2">Remember your password?
                <a href="#" className="text-dark fw-bold"
                   onClick={enterMode(MODE_LOGIN)}> Sign in!</a>
            </p>
            }

            {mode === MODE_RESTORE_PASSWORD_CONFIRM &&
            <p className="text-center mt-2 mb-2 text-muted">Didn't get a code?
                <a href="#" className="text-muted fw-bold"
                   onClick={enterMode(MODE_RESTORE_PASSWORD)}> Resend!</a>
            </p>
            }

            {[MODE_REGISTER, MODE_LOGIN].includes(mode) &&
            <div>
                <hr data-content="OR" className="hr-text mt-4 mb-4"/>
                <div className={"vstack gap-3"}>
                    {/*<button className="btn btn-block mb-1 text-white btn-facebook-social"
                            disabled={processing}
                            onClick={() => socialLogin('Facebook')}
                            href="#">
                        <i className="fa-brands fa-facebook me-2"/>
                        Continue with Facebook
                    </button>*/}
                    <button className="btn btn-block mb-1 text-white btn-google-social"
                            disabled={processing}
                            onClick={() => socialLogin('GoogleOpenID')}
                            href="#">
                        <i className="fa-brands fa-google me-2"/>
                        {mode === MODE_LOGIN ? 'Log in' : 'Register'} with Google
                    </button>
                </div>
            </div>
            }
        </div>
    );
};

const FormField = ({as: ElementType = "input", fieldType = "text", fieldId, fieldLabel, errors, errorMessage, registerFn, registerOptions = {}, ...rest}) => {
    return (
        <div className="mb-3">
            <div className={"form-floating"}>
                <ElementType
                    type={fieldType}
                    className={'form-control' + (errors[fieldId] ? ' is-invalid' : '')}
                    {...registerFn(fieldId, registerOptions)}
                    placeholder={fieldLabel}
                    {...rest}
                />
                <label>{fieldLabel}</label>
            </div>
            {errors[fieldId] &&
                <div className="invalid-feedback">{errorMessage}</div>
            }
        </div>
    )
}

export {LoginForm};
